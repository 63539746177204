/* https://codepen.io/allenchum/full/QWLRRJR */


.LeopardUserCardMainDiv{
  height:53vh;
  width:26vw;
   border-style:solid;
    border-color:white;
      position:relative;
      border-radius:8px;
       margin-bottom:2vh;
}



.ProfileNameTitle{
  background-color:black;
font-size:3vh;
color:white;
width: 100%;
height:3vh;
color:white;
font-weight: bold;
margin-top:1.5vh;
}


.ProfileImage{
  height:30vh;
  width:24.75vw;
  border-style:solid;
  border-color:white;
  object-fit:contain;
}










/* small screens ie phone screens */
@media only screen and (max-width: 1000px) {
  




  
  .LeopardUserCardMainDiv{
    height:50vh;
    width:90vw;
     border-style:solid;
      border-color:white;
        position:relative;
        border-radius:8px;
         margin-bottom:2vh;
  }
  
    
.ProfileNameTitle{
  background-color:black;
font-size:3vh;
color:white;
width: 100%;
height:3vh;
color:white;
font-weight: bold;
margin-top:1.5vh;
}


.ProfileImage{
  height:30vh;
  width:100%;
  border-style:solid;
  border-color:white;
  object-fit:contain;
}

    
      }
/* End of small screens ie phone screens */









       /* computer screens */
    @media only screen and (min-width: 1000px) {
    
         .LeopardUserCardMainDiv{
    height:53vh;
    width:26vw;
     border-style:solid;
      border-color:white;
        position:relative;
        border-radius:8px;
         margin-bottom:2vh;
  }
  
    
.ProfileNameTitle{
  background-color:black;
font-size:3vh;
color:white;
width: 100%;
height:3vh;
color:white;
font-weight: bold;
margin-top:1.5vh;
}

        
         }
       
