






/* small screens phones etc */

@media only screen and (max-width: 1000px) {

    

    .listItemButton{
        height:2.5vh;
        width:30vw;
        border:1px solid #dfe1e5;
        border-radius:4px;
        /* font-size:0.1vh; */
        font-family:Arial;
       
        left:0;
        text-align:center;
        color:white;
        /* background-color:red; */
        margin-bottom: 5vh;
       
}


  
   

    .HomeMainDiv{
        width:"80vw";
        padding-top:"15vh";
        padding-bottom:"200px";
        background-color:"black";
        background-color:"red";
        border-style:"solid";
        border-color:"white";
        align-content:"center";
        margin: "auto";
         position: "relative";
    }

    
      }
    
    

    
    
    
    
    
    
    /* computer screens */
    @media only screen and (min-width: 1000px) {
    

    .listItemButton{
        height:2vh;
        width:20vw;
        border:1px solid #dfe1e5;
        border-radius:4px;
        font-size:1vh;
        font-family:Arial;
       
        left:0;
        text-align:center;
        color:white;
        /* background-color:purple; */
}
      
      
     
        .elephantChessImage{
            margin-top: 20vh;
            width:50vh;
        }
        
        
       
           
   
   
         }
       
       
       
       
       
       
       
       
       
       
       
       
    
    
    