

/* 
to swap react styles to css use https://codepen.io/allenchum/full/QWLRRJR */



  /* small screens phones etc */

@media only screen and (max-width: 1000px) {
  
   
 

    .urls{
        object-fit:contain;
        width:98vw;
        background-size:cover;
        border-style:solid;
        }

    
      }
    
    
    
    
    
    /* computer screens */
    @media only screen and (min-width: 1000px) {
    
      
     
        .urls{
            object-fit:contain;
            width:48vw;
            background-size:cover;
            border-style:solid;
            padding:1vh;
            }
      

           
   
         }
       
       