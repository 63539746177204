



/* small screens phones etc */

@media only screen and (max-width: 1000px) {
  
   

    .elephantChessImage{
        margin-top: 24vh;
        height: 20vh;
    }
    
    
        .registrationInput {
            width: 85%;
            height: 5vh;
            border-radius: 4px;
            position: relative;
            background-color: rgba(255,255,255,0.3);
            transition: 0.3s all;
           padding:20px;
           margin:10px;
           border: none;
           box-shadow: 0 3px 2px -1px lightgray;
           font-size: 4vw;
           
          }
          .registrationInput:hover {
            background-color: rgba(255, 255, 255, 0.45);
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 0 3px 2px -1px lightgray;
          }
    
        

          .errorCodeText {
            color:"red";
            margin-top:3vh;
             font-size:2vh;
           }
    
          .resetButton {  
            width: 45vw;
            height: 9vw;
            font-size: 4vw;
            border-radius: 4px;
            position: relative;
            background-color: lightblue;
            transition: 0.3s all;
            color: black;
           margin:10px;
           padding:5px;
           border: none;
           box-shadow: 0 3px 2px -1px lightgray;
           margin-top: 5vw;
      }
    
      .websiteText {  
        border: none;
        color:"black";
        margin-top:5vh;
          font-size:1.8vh;
         background-color: white;
    }
    
      
    
    
      }
    
    
    
    
    
    
    
    
    
    
    
    /* computer screens */
    @media only screen and (min-width: 1000px) {
    
      
     
     .elephantChessImage{
         margin-top: 20vh;
         width:50vh;
     }
     
     
    
     
         .registrationInput {
             width: 70%;
             height: 5vh;
             border-radius: 4px;
             position: relative;
             background-color: rgba(255,255,255,0.3);
             transition: 0.3s all;
            padding:20px;
            margin:10px;
            border: none;
            box-shadow: 0 3px 2px -1px lightgray;
            font-size: 2vw;
            
           }
           .registrationInput:hover {
             background-color: rgba(255, 255, 255, 0.45);
             box-shadow: 0 3px 2px -1px lightgray;
           }
     
          

           .errorCodeText {
            color:"red";
            margin-top:3vh;
             font-size:2vh;
           }
     
           .resetButton {  
            width: 30vw;
             height: 7vh;
             font-size: 3.5vh;
             border-radius: 4px;
             position: relative;
             background-color: lightblue;
             transition: 0.3s all;
            color: white;
            margin:10px;
            padding:5px;
            border: none;
            box-shadow: 0 3px 2px -1px lightgray;
            margin-top: 5vw;
       }
     
    
       .websiteText {  
        border: none;
        color:"black";
        margin-top:6vh;
          font-size:3.5vh;
         background-color: white;
    }



      }
    
    
    
    
    
    
    
    
    
    
    
    